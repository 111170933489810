<template>
    <div>
        <div v-show="endDate===null || !this.real_start">You can start this voting.</div>
        <div v-show="this.real_start && !this.endTag">This application is under voting： {{ `${day} Day ${hr}:${min}:${sec} ` }}</div>
        <div v-show="this.endTag">This voting ended on {{endDate}}</div>
    </div>
</template>

<script>
    export default {
        name: "countD",
        props:['endDate'],
        // "2018-03-10 18:26:27"
        data: function () {
            return {
                end_gg:0,
                day: 0,
                hr: 0,
                min: 0,
                sec: 0,
                endTag:false,
                real_start:false
            }
        },
        mounted: function () {
            this.judge()
        },
        methods: {
            judge(){
                if(this.endDate === null){
                    this.real_start = false;
                    this.endTag = false;
                }else {
                    this.real_start = true;
                    this.countdown();
                }
            },
            countdown() {
                const end = Date.parse(new Date(this.endDate));
                const now = Date.parse(new Date());
                const msec = end - now;
                if(msec > 0) {
                    let day = parseInt(msec / 1000 / 60 / 60 / 24);
                    let hr = parseInt(msec / 1000 / 60 / 60 % 24);
                    let min = parseInt(msec / 1000 / 60 % 60);
                    let sec = parseInt(msec / 1000 % 60);
                    this.day = day;
                    this.hr = hr > 9 ? hr : '0' + hr;
                    this.min = min > 9 ? min : '0' + min;
                    this.sec = sec > 9 ? sec : '0' + sec;
                    const that = this;
                    if (msec >= 0 && msec <= (1000 * 60 * 5)) {
                        setTimeout(function () {
                            that.countdown()
                        }, 1000)
                    } else if (msec < 0) {
                        this.endTag = true;
                    }
                }
                else{
                    this.endTag = true;
                }
            }
        },
        watch:{
            endDate(newVal, oldVal){
                this.endDate = newVal;
                this.judge();
            }
        }
    }
</script>

<style scoped>

</style>
