<template>
    <div style="width:100%;height:100px;float:left">
        <div style="width:100%;height:15px;float:left;margin-bottom: 15px">
            <div style="width:20%;height:30px;float:left;text-align: left;font-size: 1.25rem; font-weight: 800;color:rgb(245 125 68) ">
                <strong style="font-size: 1.05rem; font-weight: 600;color:rgb(245 125 68) ">  approve </strong> {{approve}}
            </div>
            <div style="width:515px;height:30px;float:left;"></div>
            <div style="width:16%;height:30px;float:left;text-align: left;font-size: 1.25rem; font-weight: 800;color:rgb(169,166,171) ">
            <!-- <strong style="font-size: 1.05rem; font-weight: 600;color:rgb(169,166,171)">  abandon </strong> {{pending}} -->
            </div>
            <div style="width:15%;height:30px;float:left;text-align: right;font-size: 1.25rem; font-weight: 800;color:#D4AC0D ">
                <strong style="font-size: 1.05rem; font-weight: 600;color:#D4AC0D ">reject </strong>{{reject}}
            </div>


        </div>
        <Card id="approve_line" :bordered="false" style="width:30%;height:30px;float:left;background-color:rgb(245 125 68)">

        </Card>
        <div id="pending_line" :bordered="false" style="width:40%;height:30px;float:left;background-color:rgb(169,166,171)">

        </div>
        <Card id="reject_line" :bordered="false" style="width:30%;height:30px;float:left;background-color:rgb(255 205 22)">

        </Card>
    </div>
</template>

<script>
    export default {
        name: "votingPic",
        props:['approve','reject','pending'],
        data: function () {
            return {

            }
        },
        methods:{
            draw_line_pic(){
                let v1 = document.getElementById('reject_line');
                let v2 = document.getElementById('approve_line');
                let v3 = document.getElementById('pending_line');
                let total = this.approve + this.reject + this.pending;
                let v1_num =(this.approve.toFixed(3))/(total.toFixed(3));
                let v2_num =(this.reject.toFixed(3))/(total.toFixed(3));
                let v3_num =(this.pending.toFixed(3))/(total.toFixed(3));
                v1.style.width = (100 * v2_num).toString() + '%';
                v2.style.width = (100 * v1_num).toString() + '%';
                v3.style.width = (100 * v3_num).toString() + '%';
            },

        },
        mounted:function(){
            this.draw_line_pic();
        },
        watch:{
            approve(newVal, oldVal){
                this.approve = newVal;
                this.draw_line_pic();
            },
            reject(newVal, oldVal){
                this.reject = newVal;
                this.draw_line_pic();
            },
            pending(newVal, oldVal){
                this.pending = newVal;
                this.draw_line_pic();
            },
        }
        /*computed:function () {
            this.draw_line_pic()
        }*/
    }
</script>

<style scoped>

</style>
