<template>
    <div>


<!--        <div v-if="!submit_tag" style="width: 100%;float: left;margin-top: 20px">-->
<!--            <div style="width: 100%;text-align: right;font-size: 1.025rem;padding-right: 10%">-->
<!--                You can launch a edit vote by fill out the following table.-->
<!--            </div>-->
<!--        </div>-->

        <Card style="margin-top: 20px; width: 80%;text-align:left;float:left; margin-left: 10%;padding: 40px 5% 20px;">
            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                Voting Description:
            </div>
            <Input v-model="ct.description" type="textarea" readonly placeholder="Enter something..."/>


            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                Project Description:
            </div>
            <Input v-model="ct.abstractInfo" type="textarea" readonly placeholder="Enter something..."/>

            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                Environment:
            </div>
            <Input v-model="ct.environment" type="textarea" readonly placeholder="Enter something..."/>


            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                dependencies:
            </div>
            <Input v-model="ct.dependencies" type="textarea" readonly placeholder="Enter something..."/>

            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                languages:
            </div>
            <Input v-model="ct.languages" type="textarea" readonly placeholder="Enter something..."/>

            <div class="input_domain">
                <span style="font-size: 1.125rem;">Estimated code size: </span>
                <Input v-model="ct.codeLines" readonly style="width: 18.75rem;">
                    <span slot="append">lines</span>
                </Input>
            </div>

            <div class="input_domain">
                <p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Share point gain ratio(code): </p>
<!--                <p style="font-size: 14px;color:darkgray">Default ratio: 100(lines)=1(share point)</p>-->
                <Input v-model="ct.codeLineLimitsForOneShare" readonly placeholder="How many lines exchange 1 point"
                       style="width: 28rem;">
                    <span slot="append">lines = 1 point</span>
                </Input>
            </div>
            <div class="input_domain">
                <p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Share point gain ratio(invest): </p>
                <Input v-model="ct.moneyorGetOneShare" readonly placeholder="How much" style="width: 20rem;">
                    <span slot="prepend"> $ </span>
                    <span slot="append">= 1 point</span>
                </Input>
            </div>

            <div class="input_domain">
                <p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Price of executable code: </p>
                <Input v-model="ct.executableCodePrice" readonly placeholder="How much" style="width: 20rem;">
                    <span slot="prepend"> $ </span>
                </Input>
            </div>


            <div class="input_domain">
                <span style="font-size: 1.125rem;">Voting time for Join/Extend: </span>
                <Input v-model="ct.votingTimeForJoin" readonly placeholder="" style="width: 20rem;">
                    <span slot="append">minutes</span>
                </Input>
            </div>
            <div class="input_domain">
                <span style="font-size: 1.125rem;">Voting time for amendment: </span>
                <Input v-model="ct.votingTimeForAmendment" readonly placeholder="" style="width: 20rem;">
                    <span slot="append">days</span>
                </Input>
            </div>


            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-top:50px;margin-bottom: 5px">
                Price of Source Code
                <p style="font-size: 14px;color:darkgray;display: inline">(Dollars per 100 lines of code)</p>:
            </div>
<!--            <p v-if="!submit_tag" style="font-size: 14px;color:darkgray"> You can just give the Initial price. You can-->
<!--                also give profit-price to adjust price in profit increasing.</p>-->
            <div class="table_unit"
                 style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">

                <div style="width:40%; float:left;display:inline">
                    Total Profit
                </div>
                <div style="width:40%;margin-left:3%;float:left;display:inline">
                    Price
                </div>
            </div>
            <Form  style="width: 100%;float:left">
                <FormItem
                        v-for="(item, index) in ct.priceList"
                        :key="index"
                        :prop="'items.' + index + '.value'"
                >
                    <div class="table_unit" style="width:100%; float:left;">
                        <div class="table_unit" style="width:40%; float:left;display:inline">
                            <div style="text-align: left;padding-left: 10%" v-if="index===0">0</div>
                            <Input readonly prefix="logo-usd" v-if="!submit_tag && index>0" type="number" v-model="item.profit"
                                   placeholder="Enter something..."></Input>
                            <div style="text-align: left;padding-left: 10%" v-if="submit_tag && index >0">
                                {{item.profit}}
                            </div>
                        </div>
                        <div class="table_unit" style="width:40%; margin-left:3%;float:left;display:inline">
                            <Input readonly prefix="logo-usd" v-if="!submit_tag" type="number" v-model="item.price"
                                   placeholder="Enter something..."></Input>
                            <div style="text-align: left;padding-left: 10%" v-if="submit_tag ">{{item.price}}</div>
                        </div>
                        <div readonly v-if="!submit_tag" class="table_unit"
                             style="width:10%; margin-left: 3%; float:left;display:inline">
<!--                            <Button long v-if="index>0" @click="handleRemove1(index)">Delete</Button>-->
                        </div>
                        <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                        </hr>
                    </div>
                </FormItem>
<!--                <FormItem v-if="!submit_tag">-->
<!--                    <div>-->
<!--                        <Button type="dashed" long @click="handleAdd1" icon="md-add"-->
<!--                                style="width: 30%;float: left;margin-left: 35%">Add item-->
<!--                        </Button>-->
<!--                    </div>-->
<!--                </FormItem>-->
            </Form>


            <div class="input_domain">
                <span style="font-size: 1.125rem;">Hierarchy diagram of the project: </span>
                <div v-if="ct.hierarchyDiagramPath.length != 34" style="">
                    <img style="width: 50rem;margin-top: 1rem;" :src="ct.hierarchyDiagramPath"/>
                </div>
                <div>
                    <div v-if="file===''">
                        No hierarchy picture here!
                    </div>
<!--                    <Upload v-if="file===''"-->
<!--                            :before-upload="handleUpload"-->
<!--                            action="">-->
<!--                        <Button icon="ios-cloud-upload-outline">Select the file to update the hierarchyDiagram</Button>-->
<!--                    </Upload>-->
<!--                    <div v-else>-->
<!--                        {{file.name}}-->
<!--                    </div>-->
                </div>
            </div>


            <div class="input_domain">
                <span style="font-size: 1.125rem;">FunctionList </span>
                <div class="table_unit"
                     style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
                    <div style="width:10%; float:left;display:inline">
                        id
                    </div>
                    <div style="width:35%; float:left;display:inline">
                        name
                    </div>
                    <div style="width:35%;margin-left:3%;float:left;display:inline">
                        description <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong>
                    </div>
                </div>
                <Form style="width: 100%;float:left">
                    <FormItem
                            v-for="(item,index) in ct.functionList"
                            :key="index"
                            :prop="'items.' + index + '.value'"
                    >
                        <div class="table_unit" style="width:100%; float:left;">
                            <div class="table_unit" style="width:11%; float:left;display:inline">
                                Function {{index+1}}
                            </div>
                            <div class="table_unit" style="width:35%; float:left;display:inline">
                                <div>
                                    <Input readonly v-model="item.name"></Input>
                                </div>
                            </div>
                            <div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
                                <div>
                                    <Input readonly v-model="item.description"></Input>
                                </div>
                            </div>
<!--                            <div class="table_unit" style="width:8%; margin-left:2%;float:left;display:inline">-->
<!--                                <Button long v-if="index>0" @click="handleRemove2(index)">Delete</Button>-->
<!--                            </div>-->
                            <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                            </hr>
                        </div>
                    </FormItem>
<!--                    <FormItem>-->
<!--                        <div>-->
<!--                            <Button type="dashed" long @click="handleAdd2" icon="md-add"-->
<!--                                    style="width: 30%;float: left;margin-left: 35%">Add item-->
<!--                            </Button>-->
<!--                        </div>-->
<!--                    </FormItem>-->
                </Form>
            </div>

            <div v-if="!voteAlready" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 0px">
                <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%">
                    <Button long type="primary" @click="myApprove()"> Approve</Button>
                </div>
                <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%">
                    <Button long type="primary" @click="myReject()"> Reject</Button>
                </div>
            </div>
            <div v-else >
                <div v-if="choice==='approve'" >
                    You have already voted to approve.
                </div>
                <div v-if="choice==='reject'" >
                    You have already voted to reject.
                </div>
            </div>
            <Modal v-model="approveModal"
                   title="Confirm"
                   @on-ok="approveOk()"
                   @on-cancel="cancel()">
                <div>
                    <div style="padding-top: 20px">
                        Make sure that you approve his all application in this page.
                    </div>
                </div>
            </Modal>
            <Modal v-model="rejectModal"
                   title="Confirm"
                   @on-ok="rejectOk()"
                   @on-cancel="cancel()">
                <div>
                    <div style="padding-top: 20px">
                        Make sure that you reject his all application in this page.
                    </div>
                </div>
            </Modal>


            <div v-show="voteAlready" style="width: 80%;float: left;margin-top: 30px">
                <Divider></Divider>
                <div>
                    <div style="width: 50%;float: left;font-size: 1.25rem;text-align: left;font-weight: 500; margin-bottom: 20px;display: inline">
                        Voting details
                        <Tag style="margin-left: 3px" type="dot" color="warning">{{voteState}}</Tag>
                    </div>
                    <div style="width: 50%;float: left;font-size: 1rem;text-align: right;font-weight: 500; margin-bottom: 20px;display: inline;">
                        <CountD v-bind:end-date="finishTime"></CountD>
                    </div>
                </div>
                <voting-pic v-bind:reject="reject" v-bind:pending="pending"
                            v-bind:approve="approve"></voting-pic>
                <Divider></Divider>
                <!--                        <div class="vote_information">-->
                <!--                            <div class="vote_information_item">start time: {{startTime}}</div>-->
                <!--                            <div class="vote_information_item">finish time: {{finishTime}}</div>-->
                <!--                        </div>-->

                <div style="width: 100%; float:left; margin-left:0% ;padding-left:  0%;padding-right:5%;padding-bottom: 50px">
                    <div style="width: 50%;float:left;padding-right:20%;margin-bottom: 20px;text-align: right">
                        <Input search placeholder="find center person"></Input>
                    </div>
                    <div style="width: 50%;float: right;margin-bottom: 40px;text-align: right">
<!--                        <Dropdown style="text-align: left">-->
<!--                            <Button type="primary">-->
<!--                                Sort-->
<!--                                <Icon type="ios-arrow-down"></Icon>-->
<!--                            </Button>-->
<!--                            <DropdownMenu slot="list">-->
<!--                                <DropdownItem>Latest</DropdownItem>-->
<!--                                <DropdownItem>Share</DropdownItem>-->
<!--                            </DropdownMenu>-->
<!--                        </Dropdown>-->
                        <Button style="margin-left: 12%" type="primary" @click="addComment">Add</Button>
                        <Modal v-model="commentModal"
                               title="Make a discussion with the applicant"
                               @on-ok="commentOk"
                        >
                            <div>
                                <div>
                                    <Input v-model="commentContent" type="textarea"
                                           placeholder="add more info...."
                                           style="margin-top:10px; width: 80%; margin-left: 10%"/>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <Divider></Divider>
                    <div style="width: 100%; float: left">
                        <List item-layout="vertical">
                            <div v-for="item in voteCommentData" :key="item.title"
                                 style="width: 100%;float: left; min-height: 100px">
                                <div style="width: 30%;padding-bottom:30px;float: left">
                                    <div style="text-align:center;width: 100%;float: left">
                                        <Avatar icon="ios-person" size="70"/>
                                    </div>
                                    <div style="text-align:center;width: 100%;float: left;margin-top: 20px;font-weight: 500;font-size: 1.025rem">
                                        {{item.userName}}
                                    </div>
                                </div>
                                <List item-layout="vertical">
                                    <ListItem style="text-align:left;width: 70%;float: left;word-wrap:break-word;word-break:normal;">
                                        {{ item.content }}
                                    </ListItem>
                                    <ListItem style="text-align:right;width: 70%;height:10px;float: left;">
                                        {{ item.time }}
                                    </ListItem>
                                </List>
                                <Divider></Divider>
                            </div>
                        </List>
                    </div>
                </div>
            </div>
            <div v-if="submit_tag" style="width: 100%;float: left;margin-top: 30px">
                <Divider></Divider>
                <div style="width: 100%;float: left;font-size: 1.125rem;text-align: left;font-weight: 500">
                    Voting details
                </div>
                <voting-pic v-bind:reject="reject" v-bind:pending="pending" v-bind:approve="approve"></voting-pic>
                <Divider></Divider>
                <div style="width: 100%; float: left">
                    <List item-layout="vertical">
                        <div v-for="item in data" :key="item.title" style="width: 100%;float: left; min-height: 100px">
                            <div style="width: 30%;padding-bottom:30px;float: left">
                                <div style="width: 100%;float: left">
                                    <Avatar icon="ios-person" size="70"/>
                                </div>
                                <div style="width: 100%;float: left;margin-top: 20px;font-weight: 500;font-size: 1.025rem">
                                    {{item.user_name}}
                                </div>
                            </div>
                            <div style="width: 60%;float: left">
                                {{ item.content }}
                            </div>
                            <Divider></Divider>
                        </div>
                    </List>
                </div>
            </div>

        </Card>

    </div>

</template>

<script>
    import CountD from "./countD";
    import VotingPic from "./votingPic";

    export default {
        name: "projectInformationEdit",
        components: {VotingPic, CountD},
        data() {
            return {
                reject: 0,
                pending: 0,
                approve: 0,
                voteAlready: false,
                approveModal: false,
                rejectModal: false,
                commentModal: false,
                commentContent:"",
                choice: "",
                voteCommentData:[],
                finishTime: null,

                file: '',
                userName: "",
                share: 0,


                data: [],
                joinVotingTime: '',
                amendmentVotingTime: '',
                columns1: [
                    {
                        title: 'Total Profit',
                        key: 'profit'
                    },
                    {
                        title: 'Price',
                        key: 'price'
                    }
                ],

                description: null,
                formDynamic: {
                    items: [
                        {
                            profit: '',
                            price: '',
                            index: 1,
                            status: 1,
                        },
                        {
                            profit: '',
                            price: '',
                            index: 2,
                            status: 2,
                        },

                    ]
                },
                formDynamic2: {
                    items: [
                        {
                            profit: '',
                            price: '',
                            index: 1,
                            status: 1,
                        },
                        {
                            profit: '',
                            price: '',
                            index: 2,
                            status: 2,
                        },

                    ]
                },

                ct: {
                    userId:"",
                    description: "",
                    abstractInfo: "",
                    codeLineLimitsForOneShare: 0,
                    codeLines: 0,
                    contributionNumber: 0,
                    creationTime: "",
                    dependencies: "",
                    environment: "",
                    executableCodePath: "",
                    executableCodePrice: 0,
                    founder: "",
                    functionList: [],
                    functionNumber: 0,
                    hierarchyDiagramPath: "",
                    hierarchyList: [],
                    id: "",
                    investorNumber: 0,
                    languages: "",
                    linesForGetOneShare: 0,
                    moneyorGetOneShare: 0,
                    name: "",
                    releaseTime: "",
                    sourceCodePrice: 0,
                    totalProfit: 0,
                    updateTime: "",
                    votingTimeForAmendment: 0,
                    votingTimeForJoin: 0,
                    priceList:[]
                },
                functionList: [],
                priceList:[]

            }

        },
        methods: {

            submit2() {
                this.modal2 = true;
            },
            real_submit() {
                let type = 'success';
                this.$Message[type]({
                    background: true,
                    content: 'Success!'
                });
                this.submit_tag = true;
            },

            cancel() {

            },
            edit2() {
                this.submit_tag = false;
            },
            init() {
                if (this.$cookies.isKey('user_name')) {
                    var identity = this.$cookies.get('user_identity');
                    if (identity === 'member') {
                        this.isMember = true;
                    } else {
                        this.isMember = false;
                    }
                } else {
                    this.isMember = false;
                }
                var Tself = this;
                var val = this.$route.query.id;
                this.editId = val;
                this.getProjectEditVote();
                this.getVoteRecord();
                this.getVoteInforamtion();
            },
            getProjectEditVote(){
                this.$axios({
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'put',
                        url: "/projectEdit/getProjectEditById",
                        data: {
                            projectId:this.editId
                        }
                    }
                ).then((response) => {
                    let data = response.data.data.items
                    console.log(data)
                    let funcList = [];
                    for(let i = 0;i< data.functionList.length;i++){
                        var func = data.functionList[i].split("---")
                        funcList.push({
                            name: func[0],
                            description: func[1]
                        })
                    }
                    let priceList = [];
                    for (let i = 0; i < data.priceList.length; i++) {
                        let str = data.priceList[i].split("---")
                        priceList.push({
                                profit: str[0],
                                price: str[1]
                            }
                        )
                    }
                    data.functionList = funcList
                    data.priceList = priceList
                    console.log(data)
                    this.ct = data;
                }).catch((error) => {
                    console.log(error);
                })
            },
            approveOk() {
                let Tself = this;
                let voteData = {
                    relatedId: this.editId,
                    choice: "approve",
                    type: "ProjectEdit",
                    userId: this.getCookie('user_id')
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/submitVote",
                        data: voteData
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === "vote success") {
                        Tself.$Message.success(data)
                        this.voteAlready = true
                        this.choice = "approve"
                        this.getVoteInforamtion()
                    } else {
                        Tself.$Message.error(data)
                    }

                }).catch((error) => {
                    console.log(error);
                })
            },

            rejectOk() {
                let Tself = this;
                let voteData = {
                    relatedId: this.editId,
                    choice: "reject",
                    type: "ProjectEdit",
                    userId: this.getCookie('user_id')
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/submitVote",
                        data: voteData
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === "vote success") {
                        Tself.$Message.success(data)
                        this.voteAlready = true
                        this.choice = "reject"
                        this.getVoteInforamtion()
                    } else {
                        Tself.$Message.error(data)
                    }

                }).catch((error) => {
                    console.log(error);
                })
            },

            getVoteInforamtion() {
                let data = {
                    relatedId: this.editId,
                    type: "ProjectEdit",
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/getVoteInformation",
                        data: data,
                    }
                ).then((response) => {

                    console.log(response)

                    let data = response.data.data.items;
                    this.voteId = data.id;
                    this.approve = data.approve;
                    this.reject = data.reject;
                    this.voteState = data.state;
                    this.startTime = data.startTime;
                    this.finishTime = data.finishTime;
                    this.state = data.state;
                    if (this.state==="Done"){
                        this.voteAlready = true;
                        Object.freeze(this.voteAlready)
                    }
                    this.getVoteCommentData();
                }).catch((error) => {
                    console.log(error);
                })
            },
            getVoteCommentData() {
                this.$axios({
                        method: 'post',
                        url: "/vote/getVoteComment",
                        data: {
                            voteId: this.voteId,
                        }
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    this.voteCommentData = data;
                }).catch((error) => {
                    console.log(error);
                })
            },

            getVoteRecord(){
                let data = {
                    relatedId: this.editId,
                    type: "ProjectEdit",
                    userId: this.getCookie('user_id')
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/getVoteRecord",
                        data: data,
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === undefined || data === null || data === "") {
                        this.voteAlready = false
                    } else {
                        this.voteAlready = true
                        this.choice = data.choice
                    }
                }).catch((error) => {
                    console.log(error);
                })
            },

            addComment() {
                this.commentModal = true;
            },
            commentOk() {
                let data = {
                    voteId: this.voteId,
                    userId: this.getCookie('user_id'),
                    content: this.commentContent,
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/addVoteComment",
                        data: data,
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if(data==="comment success"){
                        this.$Message.success(data)
                        this.getVoteCommentData()
                    }else{
                        this.$Message.error(data)
                    }
                }).catch((error) => {
                    console.log(error);
                })

            },


            handleUpload(file) {
                this.file = file;
                let fd = new FormData();
                fd.append('image', (this.file))
                this.$axios.post(
                    "/uploadImage",
                    fd,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response) => {
                    this.ct.hierarchyDiagramPath = response.data.data.filePath;
                }).catch((error) => {
                    console.log(error)
                    this.$Message.error(error.message);
                })
                return false;
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },

            myApprove() {
                this.approveModal = true;
            },
            myReject() {
                this.rejectModal = true;
            },

            // submit_update() {
            //
            //     let fcl = this.functionList.map(a => a.name + "---" + a.description)
            //     this.ct.functionNumber = fcl.length;
            //     this.ct.userId = this.getCookie("user_id")
            //     this.ct.functionList = fcl;
            //     var lopls = JSON.stringify(this.ct);
            //
            //     console.log(lopls)
            //
            //     this.$axios({
            //             headers: {
            //                 'Content-Type': 'application/json'
            //             },
            //             method: 'put',
            //             url: "/projectEdit/update",
            //             data: lopls
            //         }
            //     ).then((response) => {
            //         let pjid = response.data.data.items.projectId;
            //         if (pjid !== null && pjid !== undefined && pjid !== "") {
            //             this.$Message.success("submit success")
            //             location.href = '/#/projectDetail?id=' + (pjid);
            //         } else {
            //             this.$Message.error("submit error")
            //         }
            //
            //     }).catch((error) => {
            //         console.log(error);
            //     })
            //
            // },
        },
        mounted() {
            this.init()
        },
        watch: {
            endDate(newVal, oldVal) {
                this.endDate = newVal
            }
        }


    }
</script>

<style scoped>
    .petitle {
        text-align: left;
        font-size: 1.025rem;
        margin-bottom: 15px;
    }

    .input_domain {
        width: 100%;
        margin-bottom: 16px;
    }
</style>
